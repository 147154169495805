<template>
  <div class="form">
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item
        label="手机号码"
      >
        <a-input
          v-decorator="['phoneNumber', {rules: [{validator: compareToFirstPhone},],}]"
          placeholder="请输入手机号码"
        />
      </a-form-item>
      <a-form-item
        label="验证码"
        class="code"
      >
        <a-input
          v-decorator="['code']"
          placeholder="请输入验证码"
        />
        <img
          :src="codeImg"
          alt=""
          @click="getImgcode"
        >
      </a-form-item>
      <a-form-item
        label="短信验证码"
        class="code"
      >
        <a-input
          v-decorator="['phoneCode']"
          placeholder="请输入短信验证码"
        />
        <a
          v-if="isTime"
          @click="getPhcode"
        >获取验证码</a>
        <span v-else>{{ time }}s重新获取</span>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button
          type="primary"
          html-type="submit"
        >
          下一步
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { graphicsCode, smsCode } from '@/api/code.js';
import { checkRetrieveCode } from '@/api/login/register.js';
export default {
  name: 'AutInformation',
  data() {
    return {
      isTime: true,
      timer: '',
      time: 10,
      codeImg: '',
      form: this.$form.createForm(this, { name: 'coordinated' }),
    };
  },
  mounted() {
    this.getImgcode();
  },
  methods: {
    /* 图片验证码 */
    getImgcode() {
      graphicsCode({ source: 'ACC_RETRIEVE_CODE' }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    /* 获取验证码 */
    getPhcode() {
      this.timer = '';
      this.form.validateFields((err, values) => {
        smsCode({ source: 'ACC_RETRIEVE_CODE', checkCode: values.code, phoneNum: values.phoneNumber }).then(res => {
          this.isCode = res.data;
          if (res.data.status === 'FAIL') {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      });
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    // 下一步
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          checkRetrieveCode({ phoneCode: values.phoneCode, phoneNum: values.phoneNumber }).then(res => {
            if (res.data.status === 'FAIL') {
              this.$message.warning(res.data.errorMsg);
            } else {
              this.$parent.getCurrent(1);
            }
          });
        }
      });
    },
    compareToFirstPhone(rule, value, callback) {
      const pattern = /^1[0-9]{10}$/;
      if (pattern.test(value)) {
        callback();
      } else {
        callback('请输入正确的手机号');
      }
    },
  },

};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  justify-content: center;
  margin: 100px 0;
  /deep/ .ant-form-item-label {
    width: 100px;
  }
  /deep/ .ant-form-item label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  /deep/ .ant-form-item-children-icon {
    right: 80px;
  }
  input {
    width: 280px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 3px;
  }
  button {
    width: 280px;
    height: 44px;
    background: #007CE2;
    box-shadow: 0px 5px 8px 0px rgba(136, 189, 232, 0.3);
    border-radius: 3px;
    margin-left: 21px;
  }
  .code {
    position: relative;
    img {
      width: 84px;
      height: 27px;
      position: absolute;
      top: -4px;
      right: 0;
      background: #fff;
      cursor: pointer;
    }
    a {
      position: absolute;
      top: -10px;
      right: 10px;
    }
    span {
      position: absolute;
      top: -10px;
      right: 10px;
      color: #aaa;
    }
  }
}
</style>
